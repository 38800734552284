import styled from 'styled-components';
import { TitleText } from '../Text';
import { getButtonStyle } from './utils';
import { IButtonVariant } from './types';

interface IProps {
  variant: IButtonVariant;
}

export const Container = styled.button<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  padding: 8px 16px;
  margin: 0;
  border: none;
  border-radius: 24px;
  background: ${(props) =>
    getButtonStyle(props.theme, props.variant).background};
`;

export const Label = styled(TitleText)<IProps>`
  color: ${(props) => getButtonStyle(props.theme, props.variant).label};
`;
