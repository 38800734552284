import styled from 'styled-components';
import { LightText, TitleText } from '../Text';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const NameContainer = styled.div``;

export const LinksContainer = styled.div`
  margin-top: 8px;
`;

export const LinkItemContainer = styled.div`
  margin: 4px 0;
`;

export const NameLabel = styled(TitleText)`
  color: ${(props) => props.theme.colors.text.primary.inverted};
`;

export const LinkLabel = styled(LightText)`
  color: ${(props) => props.theme.colors.text.primary.inverted};
`;
