import React from 'react';
import {
  Container,
  DetailsContainer,
  PlaceholderContainer,
  PositionLabel,
  ReviewLabel,
  NameLabel,
} from './style';
import Placeholder from '../Placeholder';

interface Props {
  name: string;
  review: string;
  description: string;
}

const ReviewListItem: React.FC<Props> = (props) => {
  const { review, name, description } = props;

  return (
    <Container>
      <PlaceholderContainer>
        <Placeholder />
      </PlaceholderContainer>

      <DetailsContainer>
        <ReviewLabel>{review}</ReviewLabel>
        <NameLabel>{name}</NameLabel>
        <PositionLabel>{description}</PositionLabel>
      </DetailsContainer>
    </Container>
  );
};

export default ReviewListItem;
