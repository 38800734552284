import React from 'react';
import {
  Container,
  DetailsContainer,
  PlaceholderContainer,
  DescriptionLabel,
  NameLabel,
  ButtonContainer,
} from './style';
import Placeholder from '../Placeholder';
import Button from '../Button';

interface Props {
  name: string;
  description: string;
  action: string;
}

const ExperienceListItem: React.FC<Props> = (props) => {
  const { name, description, action } = props;

  return (
    <Container>
      <PlaceholderContainer>
        <Placeholder />
      </PlaceholderContainer>

      <DetailsContainer>
        <NameLabel>{name}</NameLabel>
        <DescriptionLabel>{description}</DescriptionLabel>
      </DetailsContainer>

      <ButtonContainer>
        <Button label={action} />
      </ButtonContainer>
    </Container>
  );
};

export default ExperienceListItem;
