import React from 'react';
import {
  Container,
  DetailsContainer,
  DescriptionLabel,
  NameLabel,
} from './style';
import { CircleCheck } from '../../assets';

interface Props {
  name: string;
  description: string;
}

const CapabilityListItem: React.FC<Props> = (props) => {
  const { name, description } = props;

  return (
    <Container>
      <CircleCheck width={24} height={24} />

      <DetailsContainer>
        <NameLabel>{name}</NameLabel>
        <DescriptionLabel>{description}</DescriptionLabel>
      </DetailsContainer>
    </Container>
  );
};

export default CapabilityListItem;
