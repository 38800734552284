import styled from 'styled-components';
import { BigTextItalic } from '../../../../../ui';

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  background: ${(props) => props.theme.colors.branding.accent};
`;

export const LogoContainer = styled.div`
  padding: 32px;
  width: 300px;
`;

export const LogoLabel = styled(BigTextItalic)`
  color: ${(props) => props.theme.colors.text.primary.inverted};
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const FooterGroupContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 8px 0;
`;
