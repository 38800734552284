import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Experiences from './components/Experiences';
import Team from './components/Team';
import Brands from './components/Brands';
import Reviews from './components/Reviews';
import Stats from './components/Stats';
import Capabilities from './components/Capabilities';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';

const Home: React.FC = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Experiences />
      <Team />
      <Brands />
      <Reviews />
      <Stats />
      <Capabilities />
      <Portfolio />
      <Footer />
    </div>
  );
};

export default Home;
