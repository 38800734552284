import styled from 'styled-components';
import { TitleText } from '../Text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const PlaceholderContainer = styled.div`
  width: 100%;
  height: 150px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 4px 0;
`;

export const NameLabel = styled(TitleText)`
  align-self: center;
`;
