import React from 'react';
import {
  Container,
  DescriptionContainer,
  StatItemContainer,
  StatsContainer,
  NameLabel,
  DescriptionLabel,
} from './style';

const Stats: React.FC = () => {
  return (
    <Container>
      <DescriptionContainer>
        <NameLabel>We save you time and save you money.</NameLabel>
        <NameLabel style={{ marginTop: '16px' }}>
          We also take care of you 24/7.
        </NameLabel>
      </DescriptionContainer>

      <StatsContainer>
        <StatItemContainer>
          <NameLabel>175</NameLabel>
          <DescriptionLabel>Events Produced</DescriptionLabel>
        </StatItemContainer>

        <StatItemContainer>
          <NameLabel>35K+</NameLabel>
          <DescriptionLabel># of Happy Customers</DescriptionLabel>
        </StatItemContainer>

        <StatItemContainer>
          <NameLabel>45 yrs</NameLabel>
          <DescriptionLabel>Saved in Planning</DescriptionLabel>
        </StatItemContainer>
      </StatsContainer>
    </Container>
  );
};

export default Stats;
