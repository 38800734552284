import styled from 'styled-components';

export const BaseText = styled.span`
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colors.text.primary.main};
  font-style: normal;
`;

export const TitleText = styled(BaseText)`
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
`;

export const BigText = styled(BaseText)`
  font-weight: 900;
  font-size: 24px;
  line-height: 22px;
`;

export const LightText = styled(BaseText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const BigTextItalic = styled(BaseText)`
  font-weight: 900;
  font-size: 24px;
  line-height: 22px;
  font-style: italic;
`;
