import styled from 'styled-components';
import { BigText, LightText } from '../../../../../ui';

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 0;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 16px;
`;

export const DescriptionLabel = styled(LightText)`
  margin-top: 8px;
`;

export const NameLabel = styled(BigText)``;

export const SectionListItem = styled.div`
  margin: 8px 0;
`;
