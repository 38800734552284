import React from 'react';
import { PlaceholderImage } from '../../assets';
import { Container } from './style';

const Placeholder: React.FC = () => {
  return (
    <Container>
      <PlaceholderImage />
    </Container>
  );
};

export default Placeholder;
