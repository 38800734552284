import React from 'react';
import { Container, Label } from './style';

interface Props {
  label?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'tertiary';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: React.FC<Props> = (props) => {
  const { children, label, onClick, type = 'button', variant='primary', disabled = false } = props;

  return (
    <Container variant={variant} disabled={disabled} onClick={onClick} type={type}>
      {children || <Label variant={variant}>{label}</Label>}
    </Container>
  );
};

export default Button;
