import { Theme } from '../../modules/theme';
import { IButtonStyle, IButtonVariant } from './types';

export const getButtonStyle = (theme: Theme, variant: IButtonVariant): IButtonStyle => {
  if (variant === 'primary') {
    return {
      background: theme.colors.branding.primary,
      label: theme.colors.text.primary.inverted
    }
  } else if (variant === 'secondary') {
    return {
      background: theme.colors.background.primary,
      label: theme.colors.branding.primary,
    }
  } else {
    return {
      background: theme.colors.branding.neutral,
      label: theme.colors.text.primary.inverted
    }
  }
}
