import styled from 'styled-components';
import { LightText, TitleText } from '../Text';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
`;

export const NameLabel = styled(TitleText)``;

export const DescriptionLabel = styled(LightText)`
  margin-top: 4px;
`;
