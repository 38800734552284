import React from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { Theme } from './Theme';
import defaultTheme from './defaultTheme';

interface Props {
  theme?: Theme;
}

const ThemeProvider: React.FC<Props> = ({ theme, children }) => (
  <SCThemeProvider theme={{ ...defaultTheme, ...theme }}>
    {children}
  </SCThemeProvider>
);

export default ThemeProvider;
