import React from 'react';
import { ReviewListItem } from '../../../../../ui';
import { Container } from './style';

const Reviews: React.FC = () => {
  return (
    <Container>
      <ReviewListItem
        review="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur cupiditate debitis error ex nulla ratione recusandae sunt temporibus? Harum, laborum"
        description="CEO, Avengers"
        name="Black Widow"
      />
    </Container>
  );
};

export default Reviews;
