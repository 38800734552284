import styled from 'styled-components';
import { BigText, TitleText } from '../../../../../ui';

export const Container = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 30px;
  background-image: url('/heading-asset.jpg');
`;

export const MainLabel = styled(BigText)`
  color: ${(props) => props.theme.colors.text.primary.inverted};
`;

export const DescriptionLabel = styled(TitleText)`
  color: ${(props) => props.theme.colors.text.primary.inverted};
  margin: 8px 0 16px;
`;

export const ButtonContainer = styled.div`
  margin: 8px 0;
`;
