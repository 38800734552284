import React from 'react';
import { FooterGroup } from '../../../../../ui';
import {
  Container,
  LogoContainer,
  FooterLinksContainer,
  FooterGroupContainer,
  LogoLabel,
} from './style';

const Footer: React.FC = () => {
  return (
    <Container>
      <LogoContainer>
        <LogoLabel>happily</LogoLabel>
      </LogoContainer>

      <FooterLinksContainer>
        <FooterGroupContainer>
          <FooterGroup
            name="OUR COMPANY"
            links={['About', 'Team', 'Careers', 'Press', 'Blog', 'FAQs']}
          />
          <FooterGroup
            name="OUR COMPANY"
            links={['About', 'Team', 'Careers', 'Press', 'Blog', 'FAQs']}
          />
          <FooterGroup
            name="OUR COMPANY"
            links={['About', 'Team', 'Careers', 'Press', 'Blog', 'FAQs']}
          />
        </FooterGroupContainer>

        <FooterGroupContainer>
          <FooterGroup
            name="OUR COMPANY"
            links={['About', 'Team', 'Careers', 'Press', 'Blog', 'FAQs']}
          />
          <FooterGroup
            name="OUR COMPANY"
            links={['About', 'Team', 'Careers', 'Press', 'Blog', 'FAQs']}
          />
          <FooterGroup
            name="OUR COMPANY"
            links={['About', 'Team', 'Careers', 'Press', 'Blog', 'FAQs']}
          />
        </FooterGroupContainer>
      </FooterLinksContainer>
    </Container>
  );
};

export default Footer;
