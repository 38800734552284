import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 60px;
  background: ${(props) => props.theme.colors.background.primary};
`;

export const NavigationBar = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1;
`;
