import React from 'react';
import { Button, Placeholder } from '../../../../../ui';
import {
  Container,
  Item,
  ItemsContainer,
  TitleContainer,
  TitleLabel,
  ButtonContainer,
} from './style';

const Brands: React.FC = () => {
  return (
    <Container>
      <TitleContainer>
        <TitleLabel>Brands that use happily for their events.</TitleLabel>
      </TitleContainer>

      <ItemsContainer>
        <Item>
          <Placeholder />
        </Item>

        <Item>
          <Placeholder />
        </Item>

        <Item>
          <Placeholder />
        </Item>

        <Item>
          <Placeholder />
        </Item>
      </ItemsContainer>

      <ItemsContainer>
        <Item>
          <Placeholder />
        </Item>

        <Item>
          <Placeholder />
        </Item>

        <Item>
          <Placeholder />
        </Item>

        <Item>
          <Placeholder />
        </Item>
      </ItemsContainer>

      <ButtonContainer>
        <Button label="SEE CASE STUDIES" />
      </ButtonContainer>
    </Container>
  );
};

export default Brands;
