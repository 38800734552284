import React from 'react';
import {
  Container,
  LogoSection,
  ActionSection,
  MenuSection,
  LogoLabel,
  MenuItemLabel,
  MenuLink,
} from './style';
import Button from '../Button';

const Navbar: React.FC = () => {
  return (
    <Container>
      <LogoSection>
        <LogoLabel>happily</LogoLabel>
      </LogoSection>

      <MenuSection>
        <MenuLink href="/">
          <MenuItemLabel>SERVICES</MenuItemLabel>
        </MenuLink>

        <MenuLink href="/">
          <MenuItemLabel>WHY HAPPILY</MenuItemLabel>
        </MenuLink>

        <MenuLink href="/">
          <MenuItemLabel>PRICING</MenuItemLabel>
        </MenuLink>
      </MenuSection>

      <ActionSection>
        <Button variant={'secondary'} label="TALK TO TEAM" />
        <Button variant={'tertiary'} label="DOWNLOAD GUIDE" />
      </ActionSection>
    </Container>
  );
};

export default Navbar;
