import React from 'react';
import {
  Container,
  Section,
  NameLabel,
  DescriptionLabel,
  SectionListItem,
} from './style';
import { CapabilityListItem } from '../../../../../ui';

const Capabilities: React.FC = () => {
  return (
    <Container>
      <Section>
        <NameLabel>All of our event capabilities.</NameLabel>
        <DescriptionLabel>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex hic totam
          veniam!
        </DescriptionLabel>
      </Section>

      <Section>
        <SectionListItem>
          <CapabilityListItem
            name="Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          />
        </SectionListItem>
        <SectionListItem>
          <CapabilityListItem
            name="Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          />
        </SectionListItem>
        <SectionListItem>
          <CapabilityListItem
            name="Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          />
        </SectionListItem>
        <SectionListItem>
          <CapabilityListItem
            name="Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          />
        </SectionListItem>
      </Section>

      <Section>
        <SectionListItem>
          <CapabilityListItem
            name="Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          />
        </SectionListItem>
        <SectionListItem>
          <CapabilityListItem
            name="Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          />
        </SectionListItem>
        <SectionListItem>
          <CapabilityListItem
            name="Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          />
        </SectionListItem>
        <SectionListItem>
          <CapabilityListItem
            name="Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          />
        </SectionListItem>
      </Section>
    </Container>
  );
};

export default Capabilities;
