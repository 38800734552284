import styled from 'styled-components';
import { BigText, LightText } from '../../../../../ui';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const TitleLabel = styled(BigText)``;

export const DescriptionLabel = styled(LightText)``;

export const ItemsContainer = styled.div`
  display: flex;
  margin-top: 16px;

  & > div {
    flex: 1;
    height: 200px;
    margin-right: 16px;
  }
`;

export const ButtonContainer = styled.div`
  align-self: center;
  margin: 8px 0;
`;
