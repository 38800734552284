import React from 'react';
import {
  Container,
  NameContainer,
  LinksContainer,
  LinkItemContainer,
  LinkLabel,
  NameLabel,
} from './style';

interface Props {
  name: string;
  links: string[];
}

const FooterGroup: React.FC<Props> = (props) => {
  const { name, links } = props;

  return (
    <Container>
      <NameContainer>
        <NameLabel>{name}</NameLabel>

        <LinksContainer>
          {links.map((link) => (
            <a href="/" style={{ textDecoration: 'none' }}>
              <LinkItemContainer>
                <LinkLabel>{link}</LinkLabel>
              </LinkItemContainer>
            </a>
          ))}
        </LinksContainer>
      </NameContainer>
    </Container>
  );
};

export default FooterGroup;
