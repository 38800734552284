import React from 'react';
import { Button } from '../../../../../ui';
import {
  ButtonContainer,
  Container,
  DescriptionLabel,
  MainLabel,
} from './style';

const Header: React.FC = () => {
  return (
    <Container>
      <MainLabel>
        We produce your experiences professionally, on time, and with a little
        sizzle.
      </MainLabel>

      <DescriptionLabel>
        Custom solutions with a 1-team-per-client approach, 24/7
      </DescriptionLabel>

      <ButtonContainer>
        <Button variant="secondary" label="GET STARTED" />
      </ButtonContainer>
    </Container>
  );
};

export default Header;
