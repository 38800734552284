import styled from 'styled-components';
import { BigTextItalic, TitleText } from '../Text';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background: ${(props) => props.theme.colors.branding.primary};
`;

export const LogoLabel = styled(BigTextItalic)`
  color: ${(props) => props.theme.colors.text.primary.inverted};
`;

export const LogoSection = styled.div`
  display: flex;

  padding: 0 16px;
`;

export const MenuSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const MenuItemLabel = styled(TitleText)`
  padding: 0 16px;
  color: ${(props) => props.theme.colors.text.primary.inverted};
`;

export const ActionSection = styled.div`
  display: flex;
  padding: 0 16px;
  & > button {
    margin: 0 8px;
  }
`;

export const MenuLink = styled.a`
  text-decoration: none;
`;
