import React from 'react';
import { Placeholder } from '../../../../../ui';
import {
  Container,
  DescriptionContainer,
  DescriptionLabel,
  EmphasisedLabel,
  PlaceholderContainer,
} from './style';

const Hero: React.FC = () => {
  return (
    <Container>
      <DescriptionContainer>
        <DescriptionLabel>
          Lorem ipsum dolor sit amet, consectetur adipisicing{' '}
          <EmphasisedLabel>sit amet, consectetur adipisicing</EmphasisedLabel>{' '}
          elit. Assumenda id, illum iste nam nesciunt nobis
        </DescriptionLabel>
      </DescriptionContainer>

      <PlaceholderContainer>
        <Placeholder />
      </PlaceholderContainer>
    </Container>
  );
};

export default Hero;
