import React from 'react';
import { TeamListItem } from '../../../../../ui';
import { Container, ItemsContainer, TitleContainer, TitleLabel } from './style';

const Team: React.FC = () => {
  return (
    <Container>
      <TitleContainer>
        <TitleLabel>Our core teams that create special experiences.</TitleLabel>
      </TitleContainer>

      <ItemsContainer>
        <TeamListItem name="Strategy" action="LEARN MORE" />
        <TeamListItem name="Creative" action="LEARN MORE" />
        <TeamListItem name="Web" action="LEARN MORE" />
        <TeamListItem name="Broadcast" action="LEARN MORE" />
        <TeamListItem name="Experiential" action="LEARN MORE" />
      </ItemsContainer>
    </Container>
  );
};

export default Team;
