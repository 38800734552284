import { Theme } from './Theme';

const defaultTheme: Theme = {
  name: 'Default Theme',
  fonts: {
    primary: 'Source Sans Pro',
  },
  colors: {
    success: '#11a960',
    branding: {
      primary: '#2cc0e9',
      accent: '#4984c5',
      neutral: '#2f3437',
    },
    background: {
      primary: '#ffffff',
      neutral: '#b3b3b3',
    },
    text: {
      primary: {
        main: '#333333',
        inverted: '#ffffff',
      },
    },
  },
};

export default defaultTheme;
