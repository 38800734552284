import styled from 'styled-components';
import { BigText, TitleText } from '../Text';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const PlaceholderContainer = styled.div`
  width: 200px;
  height: 150px;
`;

export const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 8px;
`;

export const ReviewLabel = styled(BigText)`
  font-size: 24px;
`;

export const NameLabel = styled(TitleText)`
  margin-top: 8px;
`;

export const PositionLabel = styled(TitleText)`
  color: ${(props) => props.theme.colors.branding.primary};
  margin-top: 4px;
`;
