import React from 'react';
import { Navbar } from '../../../ui';
import { ThemeProvider } from '../../../modules';
import { Content, NavigationBar } from './style';
import { HomeScreen } from '../../screens';

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <>
        <NavigationBar>
          <Navbar />
        </NavigationBar>

        <Content>
          <HomeScreen />
        </Content>
      </>
    </ThemeProvider>
  );
};

export default App;
