import React from 'react';
import { Placeholder, Button } from '../../../../../ui';
import {
  Container,
  ItemsContainer,
  TitleLabel,
  DescriptionLabel,
  ButtonContainer,
} from './style';

const Portfolio: React.FC = () => {
  return (
    <Container>
      <TitleLabel>Recent client work and successes.</TitleLabel>
      <DescriptionLabel>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, dolore
        dolorum id itaque molestiae natus nobis quibusdam sed unde vitae.
      </DescriptionLabel>

      <ItemsContainer>
        <Placeholder />
        <Placeholder />
        <Placeholder />
      </ItemsContainer>

      <ButtonContainer>
        <Button label="SEE CASE STUDIES" />
      </ButtonContainer>
    </Container>
  );
};

export default Portfolio;
