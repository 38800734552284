import styled from 'styled-components';
import { BigText } from '../../../../../ui';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const TitleContainer = styled.div`
  padding: 16px 0;
`;

export const TitleLabel = styled(BigText)``;

export const ItemsContainer = styled.div`
  display: flex;

  & > div {
    flex: 1;
    margin: 8px;
  }
`;
