import styled from 'styled-components';
import { BigText } from '../../../../../ui';

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  background: ${(props) => props.theme.colors.branding.primary};
`;

export const DescriptionContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const PlaceholderContainer = styled.div`
  flex: 1;
  min-height: 200px;
`;

export const DescriptionLabel = styled(BigText)`
  color: ${(props) => props.theme.colors.text.primary.inverted};
`;

export const EmphasisedLabel = styled(BigText)`
  color: ${(props) => props.theme.colors.text.primary.main};
`;
