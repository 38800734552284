import styled from 'styled-components';
import { BigText, TitleText } from '../../../../../ui';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: ${(props) => props.theme.colors.branding.primary};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StatsContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const StatItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  flex: 1;
`;

export const DescriptionLabel = styled(TitleText)`
  color: ${(props) => props.theme.colors.text.primary.inverted};
  margin-top: 8px;
`;

export const NameLabel = styled(BigText)`
  color: ${(props) => props.theme.colors.text.primary.inverted};
  font-size: 30px;
`;
