import React from 'react';
import { ExperienceListItem } from '../../../../../ui';
import { Container, ItemsContainer, TitleContainer, TitleLabel } from './style';

const Experiences: React.FC = () => {
  return (
    <Container>
      <TitleContainer>
        <TitleLabel>Experiences that we produce</TitleLabel>
      </TitleContainer>

      <ItemsContainer>
        <ExperienceListItem
          name="Virtual"
          description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          action="LEARN MORE"
        />
        <ExperienceListItem
          name="Onsite"
          description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          action="LEARN MORE"
        />
        <ExperienceListItem
          name="Hybrid"
          description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores odit quis?"
          action="LEARN MORE"
        />
      </ItemsContainer>
    </Container>
  );
};

export default Experiences;
